// Animated Icons
// --------------------------

.#{$fa-css-prefix}-spin {
    animation: fa-spin 2s infinite linear;
}

.#{$fa-css-prefix}-pulse {
    animation: fa-spin 1s infinite steps(8);
}
