// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.
// @use '@angular/material/theming';
/* You can add global styles to this file, and also import other style files */
@use './styles/generic';
@use './styles/elements';
@use './styles/objects';
@use './styles/components';
@use './styles/utilities';
@use 'src/styles/_01-settings/colors';
// @import './styles/theme';
@import './styles/_01-settings/font-awesome/scss/fontawesome';
@import './styles/_01-settings/font-awesome/scss/solid';
@import '@medpacesoftwaredevelopment/designsystem/light-theme';

.error-text {
    color: colors.$r-300;
    text-align: justify;
}

.pcs-white {
    background: url(./assets/img/PCSLogoWhiteNoMedpace.png) no-repeat center;
    background-size: contain;
}
