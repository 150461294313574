@use '/node_modules/@medpacesoftwaredevelopment/designsystem/lib/styles/vars/colors';

$white: colors.$mds-white;

//grey
$n-500: colors.$mds-black-med;
$n-400: rgba($n-500, 0.75);
$n-300: rgba($n-500, 0.5);
$n-200: rgba($n-500, 0.25);
$n-100: rgba($n-500, 0.125);
$n-50: rgba($n-500, 0.0625);

//primary color
$db-300: colors.$mds-primary;
$db-200: rgba($db-300, 0.75);
$db-100: rgba($db-300, 0.5);

$mb-300: #1b75bc;
$mb-200: rgba(#1b75bc, 0.75);
$mb-100: rgba(#1b75bc, 0.5);
$mb-50: rgba(#1b75bc, 0.25);
$mb-25: rgba(#1b75bc, 0.125);

$lb-300: #0089ff;
$lb-200: rgba(#0089ff, 0.75);
$lb-100: rgba(#0089ff, 0.5);

//secondary color
$g-300: colors.$mds-secondary;
$g-200: rgba($g-300, 0.75);
$g-100: rgba($g-300, 0.5);

$p-300: colors.$mds-primary;
$p-200: rgba($p-300, 0.75);
$p-100: rgba($p-300, 0.5);
$p-50: rgba($p-300, 0.25);

//error color
$r-300: colors.$mds-error;
$r-200: rgba($r-300, 0.75);
$r-100: rgba($r-300, 0.5);
$r-50: rgba($r-300, 0.25);
$r-25: rgba($r-300, 0.0625);

//warning color
$w-300: colors.$mds-warning;
$w-200: rgba($w-300, 0.75);
$w-100: rgba($w-300, 0.5);
$w-50: rgba($w-300, 0.25);
$w-25: rgba($w-300, 0.0625);

$text-color: $n-500;
$background-color: rgb(249, 251, 252);
$snackbar-error-color: #f44336;

$w-300: colors.$mds-warning;
