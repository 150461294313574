@use './../_01-settings/colors';
@use './../_01-settings/base-variables';
@use './../tools';

.c-card {
    background-color: colors.$white;
    box-shadow: 0 0 5px 0 rgba(29, 42, 124, 0.15);
    border: 0;
    border-radius: 2px;
    padding: 2.8rem;
    margin-bottom: 2rem;

    &__ribbon {
        display: flex;
        height: auto;

        .mb-2 {
            margin-bottom: 2rem;
        }
    }

    &__item {
        flex: 1 1 100%;
        font-size: 100% !important;

        &--right {
            display: flex;
            justify-content: flex-end;

            & > a {
                text-transform: uppercase;
                text-decoration: underline;
                @include tools.fontType(bold);
                font-size: 16px;
            }

            .searchBar {
                margin-left: 4rem;
            }
        }

        &--left {
            display: flex;
            justify-content: flex-start;
        }
    }

    &__search {
        margin-left: 1rem;

        & > i {
            position: absolute;
            color: colors.$mb-200;
            left: 0.5rem;
            top: 0.5rem;
        }
    }

    &__select {
        position: relative;

        & > i {
            position: absolute;
            right: 0.5rem;
            top: 50%;
            transform: translateY(-50%);
            color: colors.$p-300;
        }
    }
}
