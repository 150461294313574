@use './../_01-settings/colors';
@use './../tools';

.c-info {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @include tools.for-size(tablet) {
        flex-direction: row;
        height: 100px;
    }

    &__item {
        flex: 0 1 100%;
        position: relative;
        height: 100%;
        margin: 1rem;

        &--left {
            flex-basis: 30%;
            width: 25%;

            @include tools.for-size(tablet) {
                margin-right: 2rem;
            }

            & > img {
                width: 100%;
                height: 100%;
            }
        }

        &--right {
            display: flex;
            align-items: center;
            flex-direction: column;
            flex-basis: 100%;
            width: 100%;

            @include tools.for-size(tablet) {
                flex-direction: row;
                width: 70%;
                flex-basis: 70%;
            }
        }
    }

    &__block {
        flex: 1 1 100%;

        &.half {
            width: 100%;

            @include tools.for-size(tablet) {
                width: 50%;
            }
        }

        &.third {
            width: 100%;

            @include tools.for-size(tablet) {
                width: 33%;
            }
        }

        &.fourth {
            width: 100%;

            @include tools.for-size(tablet) {
                width: 25%;
            }
        }

        & > h5 {
            display: block;
            color: colors.$db-300;
            padding-bottom: 0.5rem;
            @include tools.fontType(bold);
            text-transform: uppercase;
        }

        & > span {
            color: colors.$n-400;
            word-wrap: break-word;
            overflow-wrap: break-word;
        }

        & > p {
            color: colors.$n-400;
            word-wrap: break-word;
            overflow-wrap: break-word;
        }
    }
}
