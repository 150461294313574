// globally used mixins and functions
@use './_01-settings/base-variables';
@use './_01-settings/colors';
@use './_01-settings/fonts';

@mixin for-size($size) {
    @if $size == mobile {
        @media (min-width: base-variables.$bp-mobile) {
            @content;
        }
    } @else if $size == tablet {
        @media (min-width: base-variables.$bp-tablet) {
            @content;
        }
    } @else if $size == desktop {
        @media (min-width: base-variables.$bp-desktop) {
            @content;
        }
    } @else if $size == lg-desktop {
        @media (min-width: base-variables.$bp-lg-desktop) {
            @content;
        }
    } @else if $size == xl-desktop {
        @media (min-width: base-variables.$bp-xl-desktop) {
            @content;
        }
    }
}

@mixin border($size: 1px, $color: colors.$n-300) {
    border: $size solid $color;
}

@mixin circle($width, $height) {
    width: $width;
    height: $height;
    border-radius: 50%;
}

@mixin fontType($type) {
    @if $type == regular {
        font-family: Lato Regular;
    } @else if $type == thin {
        font-family: Lato Thin;
    } @else if $type == bold {
        font-family: Lato Bold;
    }
}

@mixin fa-content($weight, $color) {
    font-family: 'Font Awesome 5 Free';
    font-weight: $weight;
    color: $color;
}
