// styling for bare HTML elements (like H1, A, header, footer, …).
// These come with default styling from the browser so we must to redefine them here.

@use './_01-settings/colors';
@use './tools';

body {
    background-color: colors.$background-color;
    font-family:
        Lato Regular,
        'Helvetica Neue',
        sans-serif;
}

a {
    color: colors.$mb-300;
    cursor: pointer;

    &:hover {
        color: colors.$db-300;
    }
}

mat-hint {
    color: colors.$w-300;
}
