// class-based selectors which define undecorated design patterns, for example media object known from OOCSS such as list, radio-button.
@use './tools';
@use './_01-settings/base-variables';
@use './_01-settings/colors';

.required-asterisk {
    color: colors.$r-300 !important;
}

.o-page {
    max-width: base-variables.$bp-tablet;
    margin: 7rem 2rem 0;

    @include tools.for-size(tablet) {
        max-width: base-variables.$bp-tablet;
    }

    @include tools.for-size(desktop) {
        max-width: base-variables.$bp-desktop;
        margin: 7rem auto 0;
    }

    @include tools.for-size(lg-desktop) {
        max-width: base-variables.$bp-lg-desktop;
        margin: 7rem auto 0;
    }

    @include tools.for-size(xl-desktop) {
        max-width: base-variables.$bp-xl-desktop;
        margin: 7rem auto 0;
    }
}

.o-small-page {
    max-width: 400px;
    margin: 7rem 2rem 0;

    @include tools.for-size(desktop) {
        margin: 7rem auto 0;
    }
}

.o-grid {
    display: block;
    width: 100%;

    &__row,
    &__col {
        display: flex;
        flex-direction: column;

        &.center {
            align-items: center;
        }
    }

    &__row {
        @include tools.for-size(mobile) {
            flex-direction: row;
            justify-content: space-between;
        }

        & > .o-grid__item {
            margin-left: 1rem;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    &__item {
        flex: 1 1 100%;
        margin-bottom: 2rem;
        min-width: 25%;

        & > * {
            position: relative;
            width: 100%;
            height: 100%;
        }

        &.half {
            flex-basis: 50%;
            width: 50%;
        }

        &.third {
            flex-basis: 33%;
            width: 33%;
        }

        &.fourth {
            flex-basis: 25%;
            width: 25%;
        }
    }

    &__field {
        flex: 1 1 100%;
        min-width: 25%;
        margin-bottom: 0.8rem;

        & > * {
            position: relative;
            width: 100%;
            height: 100%;
        }

        &.half {
            flex-basis: 50%;
            width: 50%;
        }

        &.half:not(:last-child) {
            margin-right: 1rem;
        }

        &.third {
            flex-basis: 33%;
            width: 33%;
        }

        &.fourth {
            flex-basis: 25%;
            width: 25%;
        }
    }
}

.spinnerOverlay {
    display: flex;
    align-items: center;
    justify-content: center;
}

.spinnerBody {
    display: block;
    position: fixed;
}

.mat-mdc-form-field {
    display: inline !important;
}

.mat-standard-chip .mat-chip-remove,
.mat-standard-chip .mat-chip-trailing-icon {
    height: auto !important;
}

mds-button + mds-button {
    margin-left: 1rem;
}

.mat-mdc-tab-header-pagination {
    width: 5rem;
}

.snackbar-error {
    background: colors.$snackbar-error-color !important;
    background-color: colors.$snackbar-error-color !important;
    color: colors.$white !important;
    border-radius: 4px;
    white-space: pre-wrap;
    line-height: 150%;
}

.mat-mdc-snack-bar-container {
    &.snackbar-error {
        --mdc-snackbar-container-color: colors.$snackbar-error-color !important;
        --mat-mdc-snack-bar-button-color: colors.$white;
        --mat-mdc-snack-bar-button-background-color: colors.$white;
        --mdc-snackbar-supporting-text-color: colors.$white;
    }
}

// Intentionally differs from `.snackbar-error` in style to make it clear that it's only displayed in dev/test environments
// and won't be displayed to business users
.mat-mdc-snack-bar-container.snackbar-error-debug {
    background: #85144b;
    color: #ffffff;
    white-space: pre-wrap;
    line-height: 150%;
    border-radius: 7px;

    --mdc-snackbar-container-color: #85144b;
    --mdc-snackbar-supporting-text-color: #ffffff;

    --mat-mdc-snack-bar-button-background-color: #85144b;
    --mat-mdc-snack-bar-button-color: #ffffff;
}

.modalPacContainer {
    position: fixed !important;
}

.mat-hint-container {
    margin-bottom: 1.5em;

    mat-hint {
        font-size: 1.4rem;
        font-weight: normal;
    }
}

#mds-dropdown-filter-bar {
    display: flex;
}
