// utilities and helper classes with ability to override anything which goes before in the triangle.
@use './_01-settings/base-variables';

.u-ta-right {
    text-align: right;
}

.u-ta-left {
    text-align: left;
}

.u-ta-center {
    text-align: center;
}

.uppercase {
    text-transform: uppercase;
}

.flex {
    display: flex;

    &.start {
        justify-content: flex-start;
    }

    &.center {
        justify-content: center;
    }

    &.end {
        justify-content: flex-end;
    }

    &.space-between {
        justify-content: space-between;
    }

    &.space-around {
        justify-content: space-around;
    }

    &.space-evenly {
        justify-content: space-evenly;
    }

    &.top {
        align-items: start;
    }

    &.middle {
        align-items: center;
    }

    &.bottom {
        align-items: end;
    }
}
