$bp-mobile: 576px;
$bp-tablet: 768px;
$bp-desktop: 992px;
$bp-lg-desktop: 1200px;
$bp-xl-desktop: 1400px;

$fs-xsm: 1.2rem; //12px
$fs-sm: 1.4rem; //14px
$fs-text: 1.6rem; //16px
$fs-med: 1.7rem; //18px
