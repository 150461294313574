@use './_01-settings/colors';
@use './_01-settings/base-variables';
@use './tools';

@use './_02-components/card';
@use './_02-components/info-card';

.invalid-input {
    font-size: base-variables.$fs-xsm;
    color: colors.$r-300;
}

.mat-expansion-panel-header {
    align-items: stretch;
    min-height: var(--mat-expansion-header-collapsed-state-height);
    height: auto !important;
}

.c-message {
    display: none;

    &__show {
        color: colors.$r-300;
        display: block;
    }
}

.width-100 {
    width: 100%;
}

.c-form {
    width: 100%;
    background-color: colors.$white;

    &__input {
        padding-bottom: 1.5rem;
    }
}

.c-input {
    width: 100%;
    height: 3.313rem;
    @include tools.border(1px, colors.$n-200);
    border-radius: 2px;
    padding: 0 0.5rem;
    margin-bottom: 1rem;

    &--search {
        height: 2rem;
        border-color: colors.$mb-50;
        background-color: rgba(colors.$mb-300, 0.1);
        padding-left: 2rem;
        margin-bottom: 0;
    }

    &--dropdown {
        height: 2rem;
        border-color: transparent;
        border-bottom-color: colors.$p-300;
        color: colors.$p-300;
        margin-bottom: 0;
        padding-right: 2rem;
        cursor: pointer;

        & > option {
            background-color: colors.$white;
        }
    }

    &__error {
        border-color: colors.$r-300;
        margin-bottom: 0;
    }
}

.c-radio {
    &__description {
        color: colors.$n-300;
        padding-left: 4.4rem;
    }
}

.c-text-area {
    outline: none;
    resize: none;
    overflow: auto;
    @include tools.border(1px, colors.$n-200);
    padding: 0 0.5rem;
    margin-bottom: 1rem;
    width: 100%;
    height: calc(100% - 1rem);
}

.asListedOnGovIDLabel {
    font-style: italic;
    text-transform: none !important;
    font-size: 12px !important;
}

.label-warning {
    color: colors.$r-300;

    &_fileExtensionInformations_Block {
        color: colors.$db-300;
        text-align: center;
        padding: 3px;
        font-family: 'Lato Regular';
    }

    &_filesSizes {
        color: colors.$r-300;
        text-align: justify;
    }

    &_no_role {
        color: colors.$r-300;
    }
}

.expansion-panel {
    border-top: 1px solid rgba(0, 0, 0, 0.12) !important;

    ::ng-deep .mat-expansion-panel:not(.mat-expanded),
    ::ng-deep .mat-expansion-panel.mat-expanded {
        box-shadow: none !important;
    }

    ::ng-deep .mat-expansion-panel-header-title {
        color: colors.$db-300;
    }
}
